// -----------------------------------------------------------------------------
// Scripts for menu mobile
// -----------------------------------------------------------------------------

$(function () {

  $('.navbar-toggler').on('click', function () {
    $('.menu-mobile').addClass('menu-mobile--open');
    $('body').addClass('no-scroll');
  });

  $('.menu-mobile__close').on('click', function () {
    $('.menu-mobile').removeClass('menu-mobile--open');
    $('body').removeClass('no-scroll');
  });

  $('.menu-mobile a[href^="#"]').on('click', function (event) {
    var target = $(this.getAttribute('href'));
    
    if (target.length) {
      event.preventDefault();
      $('html, body').stop().animate({
        scrollTop: target.offset().top - 80
      }, 'slow');
    }
    
    $('body').removeClass('no-scroll');
    $('.menu-mobile').removeClass('menu-mobile--open');
  });

  $('.menu-mobile__overlay').scrollbar();

});