// -----------------------------------------------------------------------------
// Scripts for accordion
// -----------------------------------------------------------------------------

$(function () {

  $('.accordion__item-title').on('click', function () {
    const accordionItem = $(this).parent();
    const accordionContent = accordionItem.find('.accordion__item-content');
    const accordtionParent = $(this).parents('.accordion');

    if(accordionItem.hasClass('accordion__item--open')) {
      accordionItem.removeClass('accordion__item--open');
      accordionContent.slideUp(300);
    } else {
      if(accordtionParent.find('.accordion__item--open').length) {
        $('.accordion__item--open').find('.accordion__item-content').slideUp(300, function() {
          $('.accordion__item--open').removeClass('accordion__item--open');
          accordionItem.addClass('accordion__item--open');
          accordionContent.slideDown(300);
        });
      } else {
        accordionItem.addClass('accordion__item--open');
        accordionContent.slideDown(300);
      }
    }
  });

});