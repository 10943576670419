jQuery(document).ready(function () {
	$.extend( $.validator.messages, {
		required: "To pole jest wymagane.",
		remote: "Proszę o wypełnienie tego pola.",
		email: "Proszę o podanie prawidłowego adresu email.",
		url: "Proszę o podanie prawidłowego URL.",
		date: "Proszę o podanie prawidłowej daty.",
		dateISO: "Proszę o podanie prawidłowej daty (ISO).",
		number: "Proszę o podanie prawidłowej liczby.",
		digits: "Proszę o podanie samych cyfr.",
		creditcard: "Proszę o podanie prawidłowej karty kredytowej.",
		equalTo: "Proszę o podanie tej samej wartości ponownie.",
		extension: "Proszę o podanie wartości z prawidłowym rozszerzeniem.",
		nipPL: "Proszę o podanie prawidłowego numeru NIP.",
		phonePL: "Proszę o podanie prawidłowego numeru telefonu",
		maxlength: $.validator.format( "Proszę o podanie nie więcej niż {0} znaków." ),
		minlength: $.validator.format( "Proszę o podanie przynajmniej {0} znaków." ),
		rangelength: $.validator.format( "Proszę o podanie wartości o długości od {0} do {1} znaków." ),
		range: $.validator.format( "Proszę o podanie wartości z przedziału od {0} do {1}." ),
		max: $.validator.format( "Proszę o podanie wartości mniejszej bądź równej {0}." ),
		min: $.validator.format( "Proszę o podanie wartości większej bądź równej {0}." ),
		pattern: $.validator.format( "Pole zawiera niedozwolone znaki." )
	});

	jQuery(".footer__content form.form").validate({
		ignore: '',
		errorClass: "error-label",
		validClass: "has-success",
		errorPlacement: function (error, element) {
			$(element).parent().append(error);
		},
		unhighlight: function (element, errorClass, validClass) {
			$(element).parent().find('label.error-label').fadeOut();
		},
		submitHandler: function (form) {
			jQuery(form).find('.form__result').html('').removeClass('form__result--active form__result--error form__result--success');
			jQuery(form).addClass("form--loading");
			var form_data = jQuery(".footer__content form.form").serialize();
			jQuery.ajax({
				url: jQuery(form).attr("action"),
				type: "POST",
				data: form_data,
				dataType: "JSON",
				error: function() {
					jQuery(form).removeClass("form--loading");
					jQuery(form).find('.form__result').html('Wystąpił błąd podczas wysyłania wiadomości. Proszę spróbować ponownie');
					jQuery(form).find('.form__result').addClass('form__result--error form__result--active');
				},	
				success: function (data) {
					jQuery(form).removeClass("form--loading");
					if (data.success) {
						jQuery(form).find('.form__result').html(data.msg);
						jQuery(form).find('.form__result').addClass('form__result--success form__result--active');
						jQuery(form).get(0).reset();
					}
					else {
						jQuery(form).find('.form__result').html(data.msg);
						jQuery(form).find('.form__result').addClass('form__result--error form__result--active');
					}
				}
			});
			return false;
		},
	});
});