// -----------------------------------------------------------------------------
// Scripts for button scroll to top
// -----------------------------------------------------------------------------


$(document).ready(function () {

  var scrollTop = $(".scroll-top");

  $(window).scroll(function () {
    var topPos = $(this).scrollTop();

    // if user scrolls down - show scroll to top button
    if (topPos > 100) {
      $(scrollTop).css("opacity", "1");

    } else {
      $(scrollTop).css("opacity", "0");
    }

  });

  //Click event to scroll to top
  $(scrollTop).click(function () {
    $('html, body').animate({
      scrollTop: 0
    }, 800);
    return false;

  });

});