// -----------------------------------------------------------------------------
// Scripts for sticky header
// -----------------------------------------------------------------------------

$(function () {

  $(window).on('scroll', function () {
    if ($(window).scrollTop() > 100) {
      $('.header').addClass('header--sticky');
    } else {
      $('.header').removeClass('header--sticky');
    }
  });
  $(window).trigger('scroll');

});