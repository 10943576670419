// -----------------------------------------------------------------------------
// Scripts for header
// -----------------------------------------------------------------------------

$(document).ready(function () {

    var $animation_elements = $('.animated');
    function check_if_in_view() {
      var $window = $(window);
      var window_height = $window.height();
      var window_top_position = $window.scrollTop();
      var window_bottom_position = (window_top_position + window_height);
      $.each($animation_elements, function () {
          var $element = $(this);
          var element_height = $element.outerHeight();
          var element_top_position = $element.offset().top + 50;
          var element_bottom_position = (element_top_position + element_height);
  
          //check to see if this current container is within viewport
          if ((element_bottom_position >= window_top_position) &&
              (element_top_position <= window_bottom_position) && !$element.hasClass('go')) {
              $element.addClass('go');//.removeClass('animated_delay');
          }
      });
    }
    
    $(window).on('scroll', function() {
        check_if_in_view();
    });
    $(window).trigger('scroll');
  
});
  
  