// -----------------------------------------------------------------------------
// Scripts for top menu
// -----------------------------------------------------------------------------

$(function () {
  $('.header-menu a[href^="#"], .scroll-to-btn').on('click', function (event) {
    const target = $(this.getAttribute('href'));
  
    if (target.length) {
      event.preventDefault();

      $('html, body').stop().animate({
        scrollTop: target.offset().top - 100
      }, 'slow');
    }
  });

});